<template>
  <div class="mt-10" id="7">
        <v-container class="bloco">
            <div class="d-flex" style="justify-content:center; margin-top: 45px; margin-bottom:20px">
                <h1 class="w-100">Patrocínio</h1>
            </div>
            <div class="d-flex" style="justify-content:center; margin-top: 30px; margin-bottom:20px">
                <div>
                    <p cols="12" style="text-align:center; font-size: 20px">Baixe aqui o nosso Plano de Patrocínio com as modalidades, <br> benefícios e todas as informações que você precisa.</p>
                    <div class="d-flex" style="justify-content:center; margin-top: 40px; margin-bottom:10px">
                        <v-btn style="width: 250px;"  class="botao_patrocinio " target="_blank" download href="/files/Plano_de_Patrocínio_MTSS.pdf">DOWNLOAD EM PDF</v-btn>
                    </div>
                </div>
            </div>
            <div class="d-flex" style="justify-content:center; margin-top: 45px; margin-bottom:20px">
                <h1 class="w-100">Empresas Apoiadoras</h1>
            </div>
            <v-row class="madrinha" style="justify-content: center; align-items: center;">
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="2" lg="2" xl="2" style="display:flex; justify-content: center;">
                    <img src="../assets/img/patrocinio/ifpb.png" alt="" style="width: 75%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="2" lg="2" xl="2" style="display:flex; justify-content: center;">    
                    <img src="../assets/img/patrocinio/Uma-Ideia.png" alt="" style="width: 60%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="2" lg="2" xl="2" style="display:flex; justify-content: center;">    
                    <img src="../assets/img/patrocinio/uniesp.png" alt="" style="width: 90%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="2" lg="2" xl="2" style="display:flex; justify-content: center;">    
                    <img src="../assets/img/patrocinio/grafica_jb.png" alt="" style="width: 90%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="2" lg="2" xl="3" style="display:flex; justify-content: center;">    
                    <img src="../assets/img/patrocinio/ufpb.png" alt="" style="width: 90%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="2" lg="2" xl="3" style="display:flex; justify-content: center;">    
                    <img src="../assets/img/patrocinio/uniciv.webp" alt="" style="width: 90%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="2" lg="2" xl="3" style="display:flex; justify-content: center;">    
                    <img src="../assets/img/patrocinio/qualitare.png" alt="" style="width: 100%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="2" lg="2" xl="3" style="display:flex; justify-content: center;">    
                    <img src="../assets/img/patrocinio/sao-braz.png" alt="" style="width: 90%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="2" lg="2" xl="3" style="display:flex; justify-content: center;">    
                    <img src="../assets/img/patrocinio/logo-compass.svg" alt="" style="width: 100%;"/>
                </v-col>
            </v-row>
            <div class="d-flex" style="justify-content:center; margin-top: 45px; margin-bottom:20px">
                <h1 class="w-100">Empresas Colaboradoras</h1>
            </div>
            <v-row class="madrinha" style="justify-content: center; align-items: center;">
                <v-col class="mt-5 mb-5" cols="12" sm="12" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">
                    <img src="../assets/img/patrocinio/campus_code.png" alt="" style="width: 80%;"/>
                </v-col>
            </v-row>
            <div class="d-flex" style="justify-content:center; margin-top: 45px; margin-bottom:20px">
                <h1 class="w-100">Empresas Incentivadoras</h1>
            </div>
            <v-row class="madrinha" style="justify-content: center; align-items: center;">
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">
                    <img src="../assets/img/patrocinio/Phoebus.png" alt="" style="width: 80%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">
                    <img src="../assets/img/patrocinio/vox.png" alt="" style="width: 70%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">
                    <img src="../assets/img/patrocinio/logosogobranco.png" alt="" style="width: 70%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">
                    <img src="../assets/img/patrocinio/vsoft.svg" alt="" style="width: 70%;"/>
                </v-col>
            </v-row>
            <div class="d-flex" style="justify-content:center; margin-top: 45px; margin-bottom:20px">
                <h1 class="w-100">Empresas Realizadoras</h1>
            </div>
            <v-row class="madrinha" style="justify-content: center; align-items: center;">
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">
                    <img src="../assets/img/patrocinio/Fretebras.png" alt="" style="width: 80%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">
                    <img src="../assets/img/patrocinio/todos-empreendimento.png" alt="" style="width: 60%;"/>
                </v-col>
            </v-row>
            <div class="d-flex" style="justify-content:center; margin-top: 45px; margin-bottom:20px">
                <h1 class="w-100">Empresas Inspiradoras</h1>
            </div>
            <v-row class="madrinha" style="justify-content: center; align-items: center;">
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">
                    <img src="../assets/img/patrocinio/dock.png" alt="" style="width: 80%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="6" sm="6" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">
                    <img src="../assets/img/patrocinio/maxmilhas.png" alt="" style="width: 80%;"/>
                </v-col>
            </v-row>
            
            <div class="d-flex" style="justify-content:center; margin-top: 45px; margin-bottom:20px">
                <h1 class="w-100">Empresas Madrinhas</h1>
            </div>
            <v-row class="madrinha" style="justify-content: center; align-items: center;">
                <v-col class="mt-5 mb-5" cols="12" sm="12" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">
                    <img src="../assets/img/patrocinio/alura.png" alt="" style="width: 60%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="12" sm="12" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">    
                    <img src="../assets/img/patrocinio/Hostdime.png" alt="" style="width: 80%;"/>
                </v-col>
                <v-col class="mt-5 mb-5" cols="12" sm="12" md="4" lg="4" xl="4" style="display:flex; justify-content: center;">    
                    <img src="../assets/img/patrocinio/minsait.png" alt="" style="width: 80%;"/>
                </v-col>
                
            </v-row>
        </v-container>
      </div>
</template>

<script>
  export default {
    name: 'PatrocinadoresPage',

    data: () => ({
    }),
  }
</script>

<style>
.bloco {
    width: 90% !important;
}
.botao_patrocinio{
  background: linear-gradient(180deg, #cb0561 0%, #160029 100%);
  box-shadow: 9px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important; 
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 30px 40px !important;
  color: #FFFFFF;
}
</style>
