<template>
  <div class="mt-10">
        <v-img
          alt="mulher tech"
          class="shrink"
          src="../assets/img/header.svg"
          width="120vw"
        />
      </div>
</template>

<script>
  export default {
    name: 'NavBar',

    data: () => ({
    }),
  }
</script>

<style>
.nav{
  background: linear-gradient(89.91deg, #0E1F4B 5.49%, #B71E87 100.75%) !important;
  height: 80px !important;
}
</style>
